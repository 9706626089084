import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import './assets/HomePage.css';

const HomePage = ({ setIsConnected }) => {
    const [account, setAccount] = useState('');
    const [activeTab, setActiveTab] = useState('signIn');
    // const navigate = useNavigate();

    const connectWallet = async () => {
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum);
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await web3.eth.getAccounts();
                if (accounts.length > 0) {
                    setAccount(accounts[0]);
                    setIsConnected(true);
                    localStorage.setItem('isMetaMaskConnected', 'true');
                } else {
                    setIsConnected(false);
                }
            } catch (error) {
                console.error(error);
                setIsConnected(false);
            }
        } else {
            alert('Please install MetaMask!');
            setIsConnected(false);
        }
    };

    const handleSignUp = async (event) => {

        event.preventDefault();
        const formData = new FormData(event.target);
        const userData = {
            publickey: formData.get('publickey'),
            username: formData.get('username'),
            password: formData.get('password'),
            specialties: formData.get('specialties'),
            blockchain_address: formData.get('blockchain_address'),
            reputation: formData.get('reputation') || 0
        };

        localStorage.setItem(userData.username, JSON.stringify(userData));
        console.log('User Registered');
        alert('Sign Up Completed!');

    };

    const handleSignIn = async (event) => {

        event.preventDefault();
        const formData = new FormData(event.target);
        const username = formData.get('username');
        const password = formData.get('password'); // Compare hashed passwords in real applications
        const role = formData.get('role');

        const userData = JSON.parse(localStorage.getItem(username));

        if (userData && userData.password === password) {
            if (role === 'worker') {
                window.location.href = '/worker-dashboard';
            } else if (role === 'requester') {
                window.location.href = '/requester-dashboard';
            }

            localStorage.setItem('isLoggedIn', true)
        } else {
            console.log('Incorrect Information');
            alert('Wrong Information');
        }

    };

    return (
        <div className="home-page">
            <header className="home-header">
                ABC xPlatform - Alipour's Blockchain Crowdsourcing System
            </header>

            <section className="blockchain-connect">
                <h2>Connect to Blockchain</h2>
                {!account && (
                    <button className='connect-button' onClick={connectWallet}>Connect to Wallet</button>
                )}
                {account && <p>Account Address: {account}</p>}
            </section>

            <section className="sign-in-up">
                <div className="tabs">
                    <button
                        className={`tab-button ${activeTab === 'signIn' ? 'active' : ''}`}
                        onClick={() => setActiveTab('signIn')}
                    >
                        Sign In
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'signUp' ? 'active' : ''}`}
                        onClick={() => setActiveTab('signUp')}
                    >
                        Sign Up
                    </button>
                </div>
                <div className={`tab-content ${activeTab === 'signIn' ? 'active' : ''}`}>
                    {/* <h3>Sign In</h3> */}
                    <form onSubmit={handleSignIn}>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input type="text" id="username" name="username" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" name="password" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="role">Role</label>
                            <select id="role" name="role">
                                <option value="worker">Worker</option>
                                <option value="requester">Requester</option>
                            </select>
                        </div>
                        <button className="sign-button" type="submit">Sign In</button>
                    </form>
                </div>
                <div className={`tab-content ${activeTab === 'signUp' ? 'active' : ''}`}>
                    <h3>Sign Up</h3>
                    <form onSubmit={handleSignUp}>
                        <div className="form-group">
                            <label htmlFor="publickey">Public Key</label>
                            <input type="publickey" id="publickey" name="publickey" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input type="text" id="username" name="username" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" name="password" />
                        </div>

                        {/* <div className="form-group specialties">
                            <label>Specialties</label>
                            <div>
                                <input type="checkbox" id="specialty1" name="specialty" value="Blockchain" />
                                <label htmlFor="specialty1">Blockchain</label>
                            </div>
                            <div>
                                <input type="checkbox" id="specialty2" name="specialty" value="Data Science" />
                                <label htmlFor="specialty2">Data Science</label>
                            </div>
                            <div>
                                <input type="checkbox" id="specialty3" name="specialty" value="Web Development" />
                                <label htmlFor="specialty3">Web Development</label>
                            </div>
                        </div> */}

                        <div className="form-group">
                            <label htmlFor="specialties">Specialties (separate with commas)</label>
                            <input type="text" id="specialties" name="specialties" placeholder="e.g., Blockchain, Data Science, Web Development" />
                        </div>

                        <button className='sign-button' type="submit">Sign Up</button>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default HomePage;
