import React, { useState } from 'react';
import { useEffect } from 'react';
import './assets/WorkerDashboard.css'; // Make sure to import your CSS file

const WorkerDashboard = () => {
    const [activeTab, setActiveTab] = useState('Profile');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);

    const [workerData, setWorkerData] = useState({
        username: 'JaneDoe',
        publickey: '0x123...xyz',
        blockchainAddress: '0x456...def',
        walletBalance: '2.0 ETH',
        numberOfTasks: 15,
        reputationScore: 4.8
    });

    const [validatePendingTasks, setValidatePendingTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [tasksPendingApproval, setTasksPendingApproval] = useState([]);


    useEffect(() => {
        const loadedTasks = JSON.parse(localStorage.getItem('tasks')) || [];

        setAvailableTasks(loadedTasks.filter(task => task.status === 'Available'));
        setValidatePendingTasks(loadedTasks.filter(task => task.status === 'Validate Pending'));
        setTasksPendingApproval(loadedTasks.filter(task => task.status === 'Approve Pending'));
        setCompletedTasks(loadedTasks.filter(task => task.status === 'Completed'));

    }, []);


    const [availableTasks, setAvailableTasks] = useState([
        { id: 1, title: 'Task 1', description: 'Description for Task 1', reward: '0.5 ETH' },
        { id: 2, title: 'Task 2', description: 'Description for Task 2', reward: '0.3 ETH' },
        // ... other tasks
    ]);

    const [pendingTasks, setPendingTasks] = useState([]);


    const handleViewDetails = (taskId) => {
        console.log('Viewing details for task', taskId);
        const task = [...availableTasks, ...validatePendingTasks, ...tasksPendingApproval, ...completedTasks].find(task => task.id === taskId);
        if (task) {
            setSelectedTask(task);
            setIsModalOpen(true);
        } else {
            console.error('Task not found');
        }
    };



    const Modal = ({ isOpen, onClose, task }) => {
        if (!isOpen) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>Task Title: {task.title}</h3>
                    <p>Description: {task.description}</p>
                    <p>Reward: {task.reward}</p>
                    {/* Include other task details */}
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };

    const handleReserve = (taskId) => {
        // Find the task by ID
        const taskIndex = availableTasks.findIndex(task => task.id === taskId);
        if (taskIndex === -1) return; // Task not found

        // Show a notification
        alert(`Task ${taskId} reserved`);
    }


    const handleSubmit = (taskId) => {
        // Find the task by ID
        const taskIndex = availableTasks.findIndex(task => task.id === taskId);
        if (taskIndex === -1) return; // Task not found

        // Update the task status
        const updatedTasks = [...availableTasks];
        updatedTasks[taskIndex] = { ...updatedTasks[taskIndex], status: 'Validate Pending' };

        // Update the availableTasks state
        setAvailableTasks(updatedTasks);

        // Update local storage
        localStorage.setItem('tasks', JSON.stringify(updatedTasks));

        // Show a notification
        alert(`Task ${taskId} submitted for validation`);

        // Optionally, update the pendingTasks state if you're using it
        const newPendingTask = updatedTasks[taskIndex];
        setPendingTasks(prev => [...prev, newPendingTask]);

        setTimeout(() => {
            window.location.reload();
        }, 1000); // 3000 milliseconds = 3 seconds
    };




    const handleValidate = (taskId) => {
        // Logic to validate the task
        console.log('Validating task', taskId);

        // Update validatePendingTasks state
        const updatedValidatePendingTasks = validatePendingTasks.filter(task => task.id !== taskId);
        setValidatePendingTasks(updatedValidatePendingTasks);

        // Find the task and update its status to 'Approve Pending'
        const taskToApprove = validatePendingTasks.find(task => task.id === taskId);
        if (!taskToApprove) return; // Task not found

        const updatedTask = { ...taskToApprove, status: 'Approve Pending' };

        // Update tasksPendingApproval state
        const updatedTasksPendingApproval = [...tasksPendingApproval, updatedTask];
        setTasksPendingApproval(updatedTasksPendingApproval);

        // Update local storage with changes
        const allTasks = [...availableTasks, ...updatedValidatePendingTasks, ...updatedTasksPendingApproval, ...completedTasks];
        localStorage.setItem('tasks', JSON.stringify(allTasks));

        // Show a notification
        alert(`Task ${taskId} has been moved to 'Approve Pending' status`);

        setTimeout(() => {
            window.location.reload();
        }, 1000); // 3000 milliseconds = 3 seconds
    };


    return (
        <div className="worker-dashboard">
            <div className="sidebar">
                <button onClick={() => setActiveTab('Profile')}
                    className={activeTab === 'Profile' ? 'active' : ''}>
                    Profile
                </button>
                <button onClick={() => setActiveTab('AvailableTasks')}
                    className={activeTab === 'AvailableTasks' ? 'active' : ''}>
                    Available Tasks
                </button>
                <button onClick={() => setActiveTab('ValidatePendingTasks')}
                    className={activeTab === 'ValidatePendingTasks' ? 'active' : ''}>
                    Validate Pending Tasks
                </button>
                <button onClick={() => setActiveTab('ApprovePendingTasks')}
                    className={activeTab === 'ApprovePendingTasks' ? 'active' : ''}>
                    Approve Pending Tasks
                </button>
                <button onClick={() => setActiveTab('CompletedTasks')}
                    className={activeTab === 'CompletedTasks' ? 'active' : ''}>
                    Completed Tasks
                </button>
                {/* ... other buttons ... */}
            </div>

            <div className="content">
                {activeTab === 'Profile' && <div>
                    Profile details...

                    <div className="profile-box">
                        <h3>Worker Profile</h3>
                        <p><strong>Username:</strong> {workerData.username}</p>
                        <p><strong>Public Key:</strong> {workerData.publickey}</p>
                        <p><strong>Blockchain Address:</strong> {workerData.blockchainAddress}</p>
                        <p><strong>Wallet Balance:</strong> {workerData.walletBalance}</p>
                        <p><strong>Number of Tasks:</strong> {workerData.numberOfTasks}</p>
                        <p><strong>Reputation Score:</strong> {workerData.reputationScore}</p>
                    </div>

                </div>}
                {activeTab === 'AvailableTasks' && <div>
                    List of available tasks...

                    <div className="tasks-available">
                        {availableTasks.filter(task => task.status === 'Available').map(task => (
                            <div key={task.id} className="task">
                                <div className="task-info">
                                    <div className="task-title">{task.title}</div>
                                    <div className="task-reward">{task.reward}</div>
                                </div>
                                <div className="task-actions">
                                    <button onClick={() => handleReserve(task.id)}>Reserve as Worker</button>
                                    <button onClick={() => handleReserve(task.id)}>Reserve as Validator</button>
                                    <button onClick={() => handleViewDetails(task.id)}>View Details</button>
                                    <button onClick={() => handleSubmit(task.id)}>Submit</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}

                {activeTab === 'ValidatePendingTasks' && <div>
                    Validate Pending Tasks Content
                    <div className="tasks-pending-validation">
                        {validatePendingTasks.map(task => (
                            <div key={task.id} className="task">
                                <div className="task-title">{task.title}</div>
                                <button onClick={() => handleValidate(task.id)}>Validate</button>
                            </div>
                        ))}
                    </div>
                </div>}


                {activeTab === 'ApprovePendingTasks' && <div>
                    Approve Pending Tasks Content
                    <div className="tasks-pending-approval">
                        {tasksPendingApproval.map(task => (
                            <div key={task.id} className="task">
                                <div className="task-title">{task.title}</div>
                                <button onClick={() => handleViewDetails(task.id)}>View Details</button>
                            </div>
                        ))}
                    </div>
                </div>}


                {activeTab === 'CompletedTasks' && <div>
                    Completed Tasks Content
                    <div className="tasks-completed">
                        {completedTasks.map(task => (
                            <div key={task.id} className="task">
                                <div className="task-title">{task.title}</div>
                                <button onClick={() => handleViewDetails(task.id)}>View Details</button>
                            </div>
                        ))}
                    </div>
                </div>}


                {/* ... other content areas ... */}

                <div>
                    {/* ... existing content ... */}
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        task={selectedTask}
                    />
                </div>

            </div>
        </div>
    );
};

export default WorkerDashboard;