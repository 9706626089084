// src/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './assets/Navbar.css';
import logo from './assets/abc-logo.png';
// import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Navbar = () => {

    const isConnected = localStorage.getItem('isMetaMaskConnected');

    // const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        // localStorage.removeItem('userRole');
        // Redirect to home page or login page
        // navigate('/');
        window.location.href = '/';
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/"><img src={logo} alt="Logo" /></Link>
            </div>

            <div className="navbar-links">
                <Link to="/">Home</Link>
                <Link to="/how-it-works">How It Works</Link>
                <Link to="/about">About</Link>
            </div>
            <div className="navbar-account">
                <span style={{ color: isConnected ? 'green' : 'red' }}>
                    {isConnected ? 'Connected' : 'Disconnected'}
                </span>
                <span> | </span>
                <button className='x' onClick={handleLogout}>X</button>
            </div>
        </nav>
    );
};

export default Navbar;