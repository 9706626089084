import React, { useState } from 'react';
import { useEffect } from 'react';
import './assets/RequesterDashboard.css'; // Make sure to import your CSS file

const RequesterDashboard = () => {
    const [activeTab, setActiveTab] = useState('Profile');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);

    const [userData, setUserData] = useState({
        username: 'JohnDoe',
        publickey: '0x123...xyz',
        blockchainAddress: '0x123...abc',
        walletBalance: '1.5 ETH',
        numberOfTasks: 10,
        reputationScore: 4.5
    });

    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        reward: '',
        numberOfWorkers: '',
        specialties: '',
        status: 'Available'
    });

    const [tasksApprovePending, setTasksApprovePending] = useState([]);
    const [tasksCompleted, setTasksCompleted] = useState([]);

    useEffect(() => {
        const loadedTasks = JSON.parse(localStorage.getItem('tasks')) || [];

        const approvePendingTasks = loadedTasks.filter(task => task.status === 'Approve Pending');
        setTasksApprovePending(approvePendingTasks);

        const inProgressTasks = loadedTasks.filter(task => task.status === 'Validate Pending');
        setTasksInProgress(inProgressTasks);

        const completedTasks = loadedTasks.filter(task => task.status === 'Completed');
        setTasksCompleted(completedTasks);

    }, []);

    const handleInputChange = (e) => {
        setNewTask({ ...newTask, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Ensure the new task has the 'Available' status
        const newTaskWithStatus = { ...newTask, status: 'Available', id: Date.now() };

        // Fetch existing tasks from local storage, add the new task, and update local storage
        const tasks = JSON.parse(localStorage.getItem('tasks')) || [];
        tasks.push(newTaskWithStatus);
        localStorage.setItem('tasks', JSON.stringify(tasks));

        // Reset the newTask state to clear the form
        setNewTask({ title: '', description: '', reward: '', numberOfWorkers: '', specialties: '', status: 'Available' });

        // Show a notification
        alert("New task submitted successfully!");

        // Optionally, update any state or UI as needed after task submission
    };



    const [tasksInProgress, setTasksInProgress] = useState([
        { id: 1, title: 'Task 1', description: 'Description for Task 1' },
        { id: 2, title: 'Task 2', description: 'Description for Task 2' },
        // ... other tasks
    ]);


    const handleViewDetails = (taskId) => {
        const task = [...tasksInProgress, ...tasksApprovePending, ...tasksCompleted].find(task => task.id === taskId);
        setSelectedTask(task);
        // Optionally, if using a modal to display details, set modal visibility here
        setIsModalOpen(true); // If you're using a modal
    };

    const Modal = ({ isOpen, task, onClose }) => {
        if (!isOpen || !task) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>Task Title: {task.title}</h3>
                    <p>Description: {task.description}</p>
                    <p>Reward: {task.reward}</p>
                    {/* Include other task details as needed */}
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };


    const handleApprove = (taskId) => {

        // Logic to approve the task
        console.log('Approving task', taskId);

        // Find and update the task
        const updatedTasks = tasksApprovePending.map(task => {
            if (task.id === taskId) {
                // Remove "(Rejected)" from the title if it exists
                const updatedTitle = task.title.replace(' (Rejected)', '');

                // Update the task with the new title and status
                return { ...task, title: updatedTitle, status: 'Completed' };
            }
            return task;
        });

        // Update the state and local storage
        setTasksApprovePending(updatedTasks);
        localStorage.setItem('tasks', JSON.stringify([...tasksInProgress, ...updatedTasks, ...tasksCompleted]));

        // Move the task to the completed tasks array
        const completedTask = updatedTasks.find(task => task.id === taskId);
        setTasksCompleted(prev => [...prev, completedTask]);

        alert("Task approved successfully!");
        setTimeout(() => {
            window.location.reload();
        }, 1000); // 3000 milliseconds = 3 seconds
    };


    const handleReject = (taskId) => {
        // Logic to reject the task
        console.log('Rejecting task', taskId);

        // Find and update the task
        const updatedTasks = tasksApprovePending.map(task =>
            task.id === taskId ? { ...task, title: `${task.title} (Rejected)`, status: 'Validate Pending' } : task
        );

        // Update the state and local storage
        setTasksApprovePending(updatedTasks);
        localStorage.setItem('tasks', JSON.stringify([...tasksInProgress, ...updatedTasks, ...tasksCompleted]));

        // Optional: Move the task back to the in-progress tasks array if required
        const rejectedTask = updatedTasks.find(task => task.id === taskId);
        setTasksInProgress(prev => [...prev, rejectedTask]);

        alert("Task rejected.");
        setTimeout(() => {
            window.location.reload();
        }, 1000); // 3000 milliseconds = 3 seconds

    };


    return (
        <div className="requester-dashboard">
            <div className="sidebar">
                <button onClick={() => setActiveTab('Profile')}
                    className={activeTab === 'Profile' ? 'active' : ''}>
                    Profile
                </button>
                <button onClick={() => setActiveTab('NewTask')}
                    className={activeTab === 'NewTask' ? 'active' : ''}>
                    New Task
                </button>
                <button onClick={() => setActiveTab('InProgressTasks')}
                    className={activeTab === 'InProgressTasks' ? 'active' : ''}>
                    In Progress Tasks
                </button>
                <button onClick={() => setActiveTab('ApprovePendingTasks')}
                    className={activeTab === 'ApprovePendingTasks' ? 'active' : ''}>
                    Approve Pending Tasks
                </button>
                <button onClick={() => setActiveTab('CompletedTasks')}
                    className={activeTab === 'CompletedTasks' ? 'active' : ''}>
                    Completed Tasks
                </button>
            </div>

            <div className="content">
                {activeTab === 'Profile' && <div>
                    Profile details...

                    <div className="profile-box">
                        <h3>User Profile</h3>
                        <p><strong>Username:</strong> {userData.username}</p>
                        <p><strong>Public Key:</strong> {userData.publickey}</p>
                        <p><strong>Blockchain Address:</strong> {userData.blockchainAddress}</p>
                        <p><strong>Wallet Balance:</strong> {userData.walletBalance}</p>
                        <p><strong>Number of Tasks:</strong> {userData.numberOfTasks}</p>
                        <p><strong>Reputation Score:</strong> {userData.reputationScore}</p>
                    </div>

                </div>}
                {activeTab === 'NewTask' && <div>
                    Form to create a new task...

                    <div>
                        <form className="new-task-form" onSubmit={handleSubmit}>
                            <h3>Create New Task</h3>
                            <input
                                type="text"
                                name="title"
                                placeholder="Task Title"
                                value={newTask.title}
                                onChange={handleInputChange}
                            />
                            <textarea
                                name="description"
                                placeholder="Task Description"
                                value={newTask.description}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="reward"
                                placeholder="Task Reward"
                                value={newTask.reward}
                                onChange={handleInputChange}
                            />
                            <input
                                type="number"
                                name="numberOfWorkers"
                                placeholder="Number of Validators"
                                value={newTask.numberOfWorkers}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="specialties"
                                placeholder="Specialties"
                                value={newTask.specialties}
                                onChange={handleInputChange}
                            />
                            <button type="submit">Submit Task</button>
                        </form>
                    </div>

                </div>}

                {activeTab === 'InProgressTasks' && <div>
                    List of tasks in progress...
                    <div className="tasks-in-progress">
                        {tasksInProgress.map(task => (
                            <div key={task.id} className="task">
                                <div className="task-title">{task.title}</div>
                                <div className="task-actions">
                                    <button onClick={() => handleViewDetails(task.id)}>View Details</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}


                {activeTab === 'ApprovePendingTasks' && <div>
                    Tasks awaiting approval...
                    <div className="tasks-in-progress">
                        {tasksApprovePending.map(task => (
                            <div key={task.id} className="task">
                                <div className="task-title">{task.title}</div>
                                <div className="task-actions">
                                    <button onClick={() => handleViewDetails(task.id)}>View Details</button>
                                    <button onClick={() => handleApprove(task.id)}>Approve</button>
                                    <button onClick={() => handleReject(task.id)}>Reject</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}


                {activeTab === 'CompletedTasks' && <div>
                    List of completed tasks...
                    <div className="tasks-in-progress">
                        {tasksCompleted.map(task => (
                            <div key={task.id} className="task">
                                <div className="task-title">{task.title}</div>
                                <div className="task-actions">
                                    <button onClick={() => handleViewDetails(task.id)}>View Details</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}

                <div>
                    {/* ... existing content ... */}
                    <Modal
                        isOpen={isModalOpen}
                        task={selectedTask}
                        onClose={() => setIsModalOpen(false)}
                    />
                </div>

            </div>
        </div>
    );
};

export default RequesterDashboard;
