import React from 'react';
import './assets/HowItWorksPage.css'; // Assuming you have a CSS file for styles

function HowItWorks() {
    return (
        <div className="how-it-works">
            <h1>How It Works</h1>
            <section>
                <h2>Signing Up and Signing In</h2>
                <p>To participate in our platform, users need to sign up or sign in. This process requires a blockchain account and a connection to MetaMask, a digital wallet used for Ethereum transactions.</p>
            </section>
            <section>
                <h2>Roles in the System</h2>
                <p>There are three main roles: Requesters, Workers, and Validators. Workers can also act as Validators.</p>
            </section>
            <section>
                <h2>Requesters</h2>
                <p>Requesters create tasks on the platform. They specify the task details and set the payment amount.</p>
            </section>
            <section>
                <h2>Workers</h2>
                <p>Workers browse available tasks and complete them. After completing a task, they submit it for validation.</p>
            </section>
            <section>
                <h2>Validators</h2>
                <p>Validators review completed tasks and approve them. Workers can also be Validators, validating tasks completed by others.</p>
            </section>
            <section>
                <h2>Payments and Smart Contracts</h2>
                <p>All payments on the platform are handled through smart contracts. Requesters fund tasks, and payments are released to Workers and Validators upon task completion and validation.</p>
            </section>
        </div>
    );
}

export default HowItWorks;
