// src/Footer.js
import React from 'react';
import './assets/Footer.css'; // Make sure to create this CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <p>© {new Date().getFullYear()} ABC x - Alipour's Blockchain Crowdsourcing System. All rights reserved.</p>
            {/* Add more footer content here as needed */}
        </footer>
    );
};

export default Footer;
