import React from 'react';
import './assets/AboutPage.css'; // Assuming you will create a CSS file for styling

function About() {
    return (
        <div className="about-page">
            <h1>About Us</h1>
            <div className="about-content">
                <section>
                    <h2>Mohammad Alipour</h2>
                    <p>I am Mohammad Alipour, a master's student in IT Engineering at the University of Isfahan. My passion lies in exploring and developing cutting-edge technologies, particularly in the realm of blockchain and smart contract development.</p>
                </section>
                <section>
                    <h2>Supervisor: Dr. Noorbehbahani</h2>
                    <p>My academic journey is guided by my esteemed supervisor, Dr. Noorbehbahani, whose expertise and insights have been invaluable in my research and development endeavors in the field of information technology.</p>
                </section>
            </div>
        </div>
    );
}

export default About;
