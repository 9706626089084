import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    // const userRole = localStorage.getItem('userRole');

    if (!isLoggedIn) {
        return <Navigate to="/" />;
    }

    // if (allowedRoles && !allowedRoles.includes(userRole)) {
    //     return <Navigate to="/" />;
    // }

    return <Component {...rest} />;
};

export default ProtectedRoute;