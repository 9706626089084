import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Navbar from './Navbar';
import Footer from './Footer';
import HomePage from './HomePage';
import WorkerDashboard from './WorkerDashboard';
import RequesterDashboard from './RequesterDashboard';
import HowItWorksPage from './HowItWorksPage';
import AboutPage from './AboutPage';
import './assets/App.css';

function App() {
  const [isConnected, setIsConnected] = useState(false);

  return (
    <Router>
      <Navbar isConnected={isConnected} />
      <Routes>
        <Route exact path="/" element={<HomePage setIsConnected={setIsConnected} />} />
        <Route path="/worker-dashboard" element={
          <ProtectedRoute component={WorkerDashboard} allowedRoles={['worker']} />
        } />
        <Route path="/requester-dashboard" element={
          <ProtectedRoute component={RequesterDashboard} allowedRoles={['requester']} />
        } />
        <Route path="/how-it-works" element={<HowItWorksPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
